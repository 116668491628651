import { apiUrl } from '.';
import { authService } from './';

const search = async (data) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/users/search?name=' + data.name, requestOptions);
    return res;
}

const globalSearch = async (value) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/users/global-search?value=' + value, requestOptions);
    return res;
}

export const userService = {
    search,
    globalSearch
};