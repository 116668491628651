import { apiUrl } from './';
import { authService } from './';

const getAll = async () =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const res = await authService.handleResponse(apiUrl + '/organizations', requestOptions);
    return res.list;
}

const getOrg = async (id) =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + id, requestOptions);
    return res;
}

const getUsers = async (id) =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + id + '/dso/', requestOptions);
    return res;
}

const getUser = async (data) =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + data.orgID + '/dso/' + data.userID, requestOptions);
    return res;
}

const sendOrg = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: data
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + (data.get('ID') || ''), requestOptions);
    return res;
}

const sendUser = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + data.orgID + '/dso/' + data.userID, requestOptions);
    return res;
}

const addDSO = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + data.orgID + '/dso/', requestOptions);
    return res;
}

const sendContactPerson = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + data.orgID + '/contact-person', requestOptions);
    return res;
}

const sendUserStatus = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + data.orgID + '/dso/status', requestOptions);
    return res;
}

const removeDSO = async (data) => {
    const requestOptions = {
        method: 'DELETE'
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + data.orgID + '/dso/' + data.userID, requestOptions);
    return res;
}

const changeStatus = async (id, status) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            status
        })
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/' + id + '/status', requestOptions);
    return res;
}

export const orgService = {
    getAll,
    getOrg,
    getUsers,
    getUser,
    addDSO,
    sendOrg,
    sendUser,
    sendContactPerson,
    sendUserStatus,
    removeDSO,
    changeStatus
};