import { useEffect, useState, useCallback, useReducer, useRef } from 'react';
import Loader from '../Common/Loader';
import { orgService } from '../../Services';
import countries from '../../lib/countries';

import { uploadsUrl } from '../../Services/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClose,
    faCircleNotch
  } from '@fortawesome/free-solid-svg-icons';

const orgReducer = (state, event) => ({
    ...state,
    [event.key]: event.value
});

const EditOrganization = ({id, setEditOrg, updateItem, index, setAdd}) => {
    const [org, setOrg] = useReducer(orgReducer, {});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState(null);
    const [StateCustom, setStateCustom] = useState(false);
    const [logo, setLogo] = useState(null);
    const [formUploading, setformUploading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formSuccess, setFormSuccess] = useState(false);

    const fileRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSuccess(false);
        setFormError(false);
        setformUploading(true);

        const formData = new FormData();
        
        for (const [key, value] of Object.entries(org)) {
            formData.append(key, value);
        }

        if (logo)
            formData.append('logo', logo);
        
        const data = await orgService.sendOrg(formData);

        if (data.error)
            setFormError(data.error);
        else if (data.res > 0) {
            setFormSuccess(true);
            setTimeout(() => {
                setFormSuccess(false);
            }, 5000);

            updateItem(index, {
                ID: data.res,
                Name: org.Name,
                URL: org.URL,
                AAUSURL: org.AAUSURL
            });

            if (!id)
                setAdd(false);
        }

        setformUploading(false);
    }

    const updateLogo = (event) => {
        setLogo(event.target.files[0]);
    }
    
    const removeLogo = () => {
        setLogo(null);
    }

    const changeValue = (key, value) => {
        setOrg({
            key: key,
            value: value,
        }); 
        if (key === 'Country')
            setStates(countries.filter(item => item.country === value)[0].states || []);

        if (key === 'State')
            setStateCustom(value === 'em' ? true : false);
    }

    const fetchData = useCallback(async () => {
        if (id) {
            setLoading(true);
            const data = await orgService.getOrg(id);
            if (data.org) {
                Object.keys(data.org).forEach((key) => {
                    if (key === 'Country') {
                        const country = countries.filter(item => item.country === data.org[key]);
                        setStates(country[0] ? country[0].states : []);
                    }

                    if (key === 'State' && data.org[key] === 'em')
                        setStateCustom(true);

                    setOrg({
                        key: key,
                        value: data.org[key]
                    });
                })
            }
            if (data.error)
                setError(data.error);
            setLoading(false);
        } else 
            changeValue('Country', 'United States of America');
      }, [id])

    useEffect(() => {
            fetchData();
      }, [fetchData]);

    return <div>
        <FontAwesomeIcon
            icon={faClose}
            style={{ fontSize: 25 }}
            className="close"
            onClick={() => {
                id ? setEditOrg(null) : setAdd(false);
                document.body.classList.remove('overflow');
            }}
        />
        <h2>{id ? 'Edit' : 'Add' } organization member</h2>
        {loading ? <Loader /> : <>
            {error ? <div className="alert error">{error}</div> : ''}
            {org ? <form onSubmit={handleSubmit}>
                <input type="hidden" name="ID" defaultValue={org.ID} />
                <div className="form-part">
                    <div className="form-control">
                        <label>Name</label>
                        <input type="text" name="Name" value={org.Name || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                    </div>
                </div>
                <div className="form-part">
                    <h3>Address</h3>
                    <div>
                        <div className="form-control">
                            <label>Address 1</label>
                            <input type="text" name="Address1" value={org.Address1 || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                        <div className="form-control">
                            <label>Address 2</label>
                            <input type="text" name="Address2" value={org.Address2 || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                        <div className="form-control">
                            <label>Address 3</label>
                            <input type="text" name="Address3" value={org.Address3 || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                        <div className="form-control">
                            <label>City</label>
                            <input type="text" name="City" value={org.City || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                        <div className="form-control">
                            <label>State</label>
                            <select name="State" onChange={(e) => changeValue(e.target.name, e.target.value)} defaultValue={org.State}>
                                <option value="">Select State</option>
                                <option value="em">Enter manually</option>
                                {states ? states.map((state) => (
                                    <option value={state} key={state}>{state}</option>
                                )) : ''}
                            </select>
                            {StateCustom ? <input type="text" name="StateCustom" placeholder="Enter state manually..." value={org.StateCustom || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} /> : ''}
                        </div>
                        <div className="form-control">
                            <label>Country</label>
                            <select name="Country" onChange={(e) => changeValue(e.target.name, e.target.value)} defaultValue={id ? org.Country : 'United States of America'}>
                                <option value="">Select Country</option>
                                {countries ? countries.map((item) => (
                                    <option value={item.country} key={item.country}>{item.country}</option>
                                )) : ''}
                            </select>
                        </div>
                        <div className="form-control">
                            <label>ZIP code</label>
                            <input type="text" name="ZipCode" value={org.ZipCode || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="form-part">
                    <h3>Contact info</h3>
                    <div>
                        <div className="form-control">
                            <label>Phone</label>
                            <input type="tel" name="Phone" value={org.Phone || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                        <div className="form-control">
                            <label>Fax</label>
                            <input type="tel" name="Fax" value={org.Fax || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="form-part">
                    <h3>Websites</h3>
                    <div>
                        <div className="form-control">
                            <label>Organization Web Site</label>
                            <div className="www">
                                <input type="text" name="URL" value={org.URL || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} className="www" />
                            </div>
                        </div>
                        <div className="form-control">
                            <label>AAUS Web Site</label>
                            <div className="www">
                                <input type="text" name="AAUSURL" value={org.AAUSURL || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} className="www" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-part">
                    <h3>Logo</h3>
                    <div className="form-control img-upload">
                        <label>Logo</label>
                        <div>
                            <input ref={fileRef} type="file" name="logo" accept="image/jpg, image/jpeg, image/png, image/gif" onChange={updateLogo} />
                            <div onClick={() => {fileRef.current.click()}} className="uploader">{logo ? 'Selected file: ' + logo.name : 'Please, click here to select file'}</div>
                            {logo ? <div className="new-img">
                                <img src={URL.createObjectURL(logo)} className="form-logo" alt="logo" />
                                <FontAwesomeIcon
                                    icon={faClose}
                                    style={{ fontSize: 15 }}
                                    className="close"
                                    onClick={removeLogo}
                                />
                            </div> : ''}
                            {!logo && org.LogoFileExt ? <img src={uploadsUrl + '/logos/'+org.ID+org.LogoFileExt} className="form-logo" alt="logo" /> : ''}
                        </div>
                    </div>
                </div>
                <button type="submit" className="blue">{formUploading ? <span><FontAwesomeIcon
                    icon={faCircleNotch}
                    style={{ fontSize: 20, color: '#fff'}}
                    spin
                /> </span> : ''}Submit</button>
            </form> : ''}
            {formError ? <div className="alert error">{formError}</div> : ''}
            {formSuccess ? <div className="alert success">Done!</div> : ''}
        </>}
    </div>;
};

export default EditOrganization;