import React, { useState } from 'react';
import { Routes, Route, Outlet, BrowserRouter } from 'react-router-dom';

import Login from './Common/Login';
import Home from './Home/Home';
import Permissions from './Permissions/Permissions';
import Users from './Users/Search';
import Versions from './Versions/Versions';

import '../styles/styles.scss';

export const App = () => {
    const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);

    return (
      <BrowserRouter>
          <Routes>
              {/*<Route element={<ProtectedRoute isAllowed={!!user && user.roles.includes('admin')} setUser={setUser} />}>*/}
              <Route element={<ProtectedRoute isAllowed={!!user} setUser={setUser} />}>
                  <Route index element={<Home user={user} />} />
                  <Route path="permissions/*" element={<Permissions user={user} />} />
                  <Route path="users/*" element={<Users user={user} />} />
                  <Route path="versions/*" element={<Versions user={user} />} />
              </Route>
              <Route path="*" element={<div className="error-404">There's nothing here: 404!</div>} />
          </Routes>
      </BrowserRouter>
    );
};

export default App;

const ProtectedRoute = ({ isAllowed, children, setUser }) => {
    if (!isAllowed) {
      return <Login setUser={setUser} />;
    }
  
    return children ? children : <Outlet />;
};