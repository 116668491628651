import { useEffect, useState, useCallback } from 'react';
import Loader from '../Common/Loader';
import { orgService } from '../../Services';
import AddUser from './AddUser';
import EditUser from './EditUser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClose,
    faAddressCard,
    faPencil,
    faCircleNotch,
    faPlus,
    faRotateLeft,
    faCancel,
    faTrash
} from '@fortawesome/free-solid-svg-icons';


const EditUsers = ({id, setEditUsers, updateItem, index}) => {
    const [users, setUsers] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [contactPerson, setConctactPerson] = useState(null);
    const [tempContactPerson, setTempConctactPerson] = useState(null);
    const [editUser, setEditUser] = useState(null);
    const [addUser, setAddUser] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const removeDSO = async (user_id) => {
        setLoadingStatus(user_id);

        const data = await orgService.removeDSO({
            userID: user_id,
            orgID: id
        });

        if (data.res === 'OK')     
            fetchData();

        setLoadingStatus(0);
    }

    const changeStatus = async (user_id, user) => {
        setLoadingStatus(user_id);

        const data = await orgService.sendUserStatus({
            userID: user_id,
            status: user.status === 1 ? 0 : 1,
            orgID: id
        });

        if (data.res === 'OK')     
            fetchData();

        setLoadingStatus(0);
    }

    const changeContact = async (userID, lastName, firstName) => {
        setTempConctactPerson(userID);

        const data = await orgService.sendContactPerson({
            orgID: id,
            userID: userID
        });

        if (data.res === 'OK') {
            setConctactPerson(userID);
            setTempConctactPerson(null);
            updateItem(index, {
                FirstName: firstName,
                LastName: lastName
            });
        }
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        const data = await orgService.getUsers(id);
        if (data.users) {
            setUsers(data.users);
            setConctactPerson(data.users[0].ContactID);
        }
        if (data.error)
            setError(data.error);
        setLoading(false);
      }, [id])

    useEffect(() => {
        fetchData();
      }, [fetchData])

    return <div>
        <FontAwesomeIcon
            icon={faClose}
            style={{ fontSize: 25 }}
            className="close"
            onClick={() => {setEditUsers(null)}}
        />
        <h2>
            Edit DSO
            <FontAwesomeIcon
                icon={faPlus}
                className="plus-icon"
                style={{ fontSize: 20, color: "#A66DD4" }}
                onClick={() => {
                    if (!addUser)
                        document.body.classList.add('overflow');
                    else 
                        document.body.classList.remove('overflow');
                    setAddUser(!addUser);
                }}
            />
        </h2>
        {loading ? <Loader /> : <>
            <div className="error">{error || ''}</div>
            <div className="users-list">
                <div className="title">
                        <div className="key">ID</div>
                        <div className="name">Last name, First name</div>
                        <div className="name">Email</div>
                        <div className="contact-person">Contact Person</div>
                        <div className="actions">Actions</div>
                </div>
                {users ? users.map((user, key) => (
                    <div key={user.id}>
                        <div className="key"><span>#: </span>{key + 1}</div>
                        <div className="name"><span>Name: </span>{user.LastName}, {user.FirstName}</div>
                        <div className="name"><span>Login: </span>{user.Email}</div>
                        <div className="contact-person">
                            <span>Contact person: </span>
                                <FontAwesomeIcon
                                icon={user.id === tempContactPerson ? faCircleNotch : faAddressCard}
                                style={{ fontSize: 20 }}
                                className={contactPerson === user.id ? 'active' : ''}
                                spin={user.id === tempContactPerson ? true : false}
                                onClick={() => {if (!tempContactPerson) changeContact(user.id, user.LastName, user.FirstName)}}
                                title={contactPerson === user.id ? '' : 'Make primary'}
                            />
                        </div>
                        <div className="actions">
                            <span>Actions: </span>
                            <FontAwesomeIcon
                                icon={faPencil}
                                style={{ fontSize: 20 }}
                                onClick={() => {
                                    setEditUser(user.id);
                                    document.body.classList.add('overflow');
                                }}
                            />
                            {loadingStatus === user.id ? <FontAwesomeIcon
                                icon={faCircleNotch}
                                style={{ fontSize: 20, color: "#ccc" }}
                                spin
                            /> :
                            <FontAwesomeIcon
                                icon={user.status === 1 ? faCancel : faRotateLeft}
                                style={{ fontSize: 20 }}
                                className={user.status === 1 ? 'red' : 'green'}
                                title={user.status === 1 ? 'Block user' : 'Unblock user'}
                                onClick={() => {
                                    changeStatus(user.id, user);
                                }}
                            />}
                            {loadingStatus === user.id ? <FontAwesomeIcon
                                icon={faCircleNotch}
                                style={{ fontSize: 20, color: "#ccc" }}
                                spin
                            /> : 
                            <FontAwesomeIcon
                                icon={faTrash}
                                style={{ fontSize: 20 }}
                                title="Remove DSO"
                                onClick={() => {
                                    removeDSO(user.id);
                                }}
                            />}
                        </div>
                    </div>
                )) : ''}
            </div>
        </>}
        {editUser ? <EditUser id={id} editUser={editUser} setEditUser={setEditUser} updateUsers={fetchData} /> : ''}
        {addUser ? <AddUser id={id} setAddUser={setAddUser} updateUsers={fetchData} /> : ''}
    </div>;
};

export default EditUsers;