import { apiUrl } from '.';

const login = async (credentials) => {
    credentials.admin = true;
    try {
        const response = await fetch(apiUrl + '/auth/login', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });
        const user = await response.json();
        return user;
      } catch (error) {
        return error;
      }
}

const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
    //setUser(null);
}

const handleResponse = async (url, args) => {
    try {
        const response = await fetch(url + (url.indexOf('?') > -1 ? '&' : '?') + 'uid=' +  JSON.parse(localStorage.getItem('user')).uid + '&hid=' +  JSON.parse(localStorage.getItem('user')).hid , args);

        const data = await response.json();
        if (data.logout)
            logout();
        return data;
    } catch (error) {
        return error;
    }
}

export const authService = {
    handleResponse,
    login,
    logout
};