import { useState } from 'react';
import Layout from '../Common/Layout';
import Loader from '../Common/Loader';

import { userService } from '../../Services';
import { uploadsUrl } from '../../Services/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faUser
  } from '@fortawesome/free-solid-svg-icons';

const Search = ({user}) => {
    const [value, setValue] = useState('');
    const [users, setUsers] = useState([]);
    const [orgs, setOrgs] = useState([]);
    const [loading, setLoading] = useState(false);

    const statuses = {
        0: 'Inactive',
        1: 'Active',
        2: 'Unapproved'
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        if (value) {
            setLoading(true);
            const data = await userService.globalSearch(value);
            if (data.res === 'OK') {
                setUsers(data.users);
                setOrgs(data.orgs);
                setLoading(false);
            }
        }
    }

    return <Layout>
            <div className="top-panel">
                <h1>
                    <FontAwesomeIcon
                        icon={faUser}
                        style={{ fontSize: 25, color: "#59748a" }}
                    />
                    Diver search
                </h1>
            </div>
            <div className="search-patterns">
                <p>Please enter one of the following:</p>
                <ul>
                    <li>Last name</li>
                    <li>First name</li>
                    <li>Last name, First name</li>
                    <li>Email address</li>
                </ul>
            </div>
            <form className="search-user" onSubmit={handleSubmit}>
                <span>
                    <FontAwesomeIcon
                        icon={faSearch}
                        style={{ fontSize: 20, color: "#59748a" }}
                    />
                </span>
                <input type="text" name="search" value={value} onChange={(event) => setValue(event.target.value)} />
                <button className="blue">Search</button>
            </form>
            {loading ? <Loader/> : 
            (users.length ? <div className="global-search-users">
                {users.map(item => (
                    <div>
                        <div className="info">
                            <span>
                                {item.Photo ? <img src={uploadsUrl + '/users/'+item.Photo} className="form-photo" alt="Avatar" /> : ''}
                            </span>
                            <div>
                                <p><strong>{item.name}</strong></p>
                                <p>{item.Email}</p>
                            </div>
                        </div>
                        
                        {orgs[item.id] ? <div className="orgs">{orgs[item.id].map(org => (
                            <div>
                                <p><a href={`https://${org.AAUSURL}`} target="_blank" rel="noreferrer">{org.Name}</a></p>
                                <p>{org.groupname}</p>
                                <p className={`status_${org.status}`}>{statuses[org.status]}</p>
                            </div>
                        ))}</div> : ''}
                    </div>
                ))}
            </div> : <></>)}
        </Layout>;
};

export default Search;