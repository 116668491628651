import { useEffect, useState, useCallback, useReducer } from 'react';
import Layout from '../Common/Layout';
import AddOrganization from './AddOrganization';
import EditOrganization from './EditOrganization';
import EditUsers from './EditUsers';
import { orgService } from '../../Services';
import Loader from '../Common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faPencil,
    faUsers,
    faSearch,
    faPlus,
    faSun,
    faMoon,
    faCircleNotch
  } from '@fortawesome/free-solid-svg-icons';

const listReducer = (state, event) => ({
    ...state,
    [event.key]: {
        ...state[event.key],
        ...event.value
    }
})

const Home = ({user}) => {
    const [list, setList] = useReducer(listReducer, {});
    const [loading, setLoading] = useState(null);
    const [editUsers, setEditUsers] = useState(null);
    const [editOrg, setEditOrg] = useState(null);
    const [search, setSearch] = useState(null);
    const [add, setAdd] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(0);

    const updateItem = (key, data) => {
        setList({
            key: key,
            value: data
        });
    };

    const changeStatus = async (id, key, status) => {
        setLoadingStatus(id);

        const data = await orgService.changeStatus(id, status);
        if (data.res === 'OK') {
            setList({
                key: key,
                value: {
                    ...list[key],
                    Status: status
                }
            });
        }

        setLoadingStatus(0);
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        const data = await orgService.getAll();
        data.forEach((item, key) => {
            setList({
                key: key,
                value: item
            });
        });
        setLoading(false);
      }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return <Layout>
        <>
            <div className="top-panel">
                <h1>
                    <FontAwesomeIcon
                        icon={faGlobe}
                        style={{ fontSize: 25, color: "#59748a" }}
                    />
                    ORGANIZATION MEMBERS
                    <FontAwesomeIcon
                        icon={faPlus}
                        className="plus-icon"
                        style={{ fontSize: 20, color: "#4176c6" }}
                        onClick={() => setAdd(!add)}
                    />
                </h1>
                <div className="search">
                    <FontAwesomeIcon
                        icon={faSearch}
                        style={{ fontSize: 20, color: "#3B76EF" }}
                    />
                    <input name="search" placeholder="Enter Organization Member name..." onKeyUp={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            {loading ? 
                <Loader/> : 
                <div className="org-list">
                    <div>
                        <div className="header">
                            <div className="id">ID</div>
                            <div className="name">Organization Member</div>
                            <div className="site-url">Public website</div>
                            <div className="aaus-url">AAUS site</div>
                            <div className="contact">Contact person</div>
                            <div className="actions">Actions</div>
                        </div>
                    </div>
                    {Object.keys(list).length ? Object.entries(list).map(([key, item]) => {// eslint-disable-next-line
                        if (search && (item.Name.toLowerCase().indexOf(search.toLowerCase()) < 0 && (item.FirstName + ' ' + item.LastName).toLowerCase().indexOf(search.toLowerCase()) < 0))
                            return false;
                        return (
                            <div key={item.Name}>
                                <div>
                                    <div className="id">{item.ID}</div>
                                    <div className="name"><strong className="poiner" onClick={() => {
                                                setEditOrg(editOrg === item.ID ? null : item.ID);
                                                setEditUsers(null);
                                            }}>{item.Name}</strong></div>
                                    <div className="site-url">
                                        <span>Public website: </span>
                                        {item.URL ?
                                            <a href={'http://' + item.URL} target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon
                                                    icon={faGlobe}
                                                    style={{ fontSize: 20, color: "#3B76EF" }}
                                                />
                                                <span>{item.URL}</span>
                                            </a> : ''
                                        }
                                    </div>
                                    <div className="aaus-url">
                                        <span>AAUS URL: </span>
                                        { item.AAUSURL ?
                                            <a href={'http://' + item.AAUSURL} target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon
                                                    icon={faGlobe}
                                                    style={{ fontSize: 20, color: "#3B76EF" }}
                                                />
                                                <span>{item.AAUSURL}</span>
                                            </a> : ''
                                        }
                                    </div>
                                    <div className="contact"><span>Contact person: </span>{item.FirstName} {item.LastName}</div>
                                    <div className="actions">
                                        <span>Actions: </span>
                                        {loadingStatus === item.ID ? <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                style={{ fontSize: 20, color: "#ccc" }}
                                                spin
                                            /> : <FontAwesomeIcon
                                            icon={item.Status ? faMoon : faSun}
                                            style={{ fontSize: 20, color: "#3B76EF" }}
                                            onClick={() => {changeStatus(item.ID, key, !item.Status)}}
                                            title={item.Status ? "Disable" : "Enable"}
                                        />}
                                        <FontAwesomeIcon
                                            icon={faPencil}
                                            style={{ fontSize: 20, color: "#3B76EF" }}
                                            onClick={() => {
                                                setEditOrg(editOrg === item.ID ? null : item.ID);
                                                setEditUsers(null);
                                            }}
                                            title="Edit"
                                        />
                                        <FontAwesomeIcon
                                            icon={faUsers}
                                            style={{ fontSize: 20, color: "#3B76EF" }}
                                            onClick={() => {
                                                setEditUsers(editUsers === item.ID ? null : item.ID);
                                                setEditOrg(null);
                                            }}
                                            title="Edit DSO"
                                        />
                                    </div>
                                </div>
                                <div className={`edit-org ${editOrg === item.ID ? 'open' : ''}`}>
                                    {editOrg === item.ID ? <EditOrganization id={editOrg} setEditOrg={setEditOrg} updateItem={updateItem} index={key} /> : ''}
                                </div>
                                <div className={`edit-users ${editUsers === item.ID ? 'open' : ''}`}>
                                    {editUsers === item.ID ? <EditUsers id={editUsers} setEditUsers={setEditUsers} updateItem={updateItem} index={key} /> : ''}
                                </div>
                            </div>
                            )
                        }) : <div className="empty-list">No organizations</div>}
                </div>
            }
            {add ? <AddOrganization setEditOrg={setEditOrg} updateItem={updateItem} index={Object.keys(list).length} setAdd={setAdd} /> : ''}
        </>
    </Layout>;
};

export default Home;