import { Link, useLocation } from 'react-router-dom';

const Nav = () => {
    const location = useLocation();
    
    return <nav className="internal-nav">
        <Link to="/permissions" className={location.pathname ==='/permissions' ? 'active' : ''}>
            Permissions
        </Link>

        <Link to="/permissions/groups" className={location.pathname === '/permissions/groups' ? 'active' : ''}>
            Groups
        </Link>

        <Link to="/permissions/admins" className={location.pathname === '/permissions/admins' ? 'active' : ''}>
            Global admins
        </Link>
    </nav>;
};

export default Nav;