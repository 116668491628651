//import { useEffect, useState, useCallback } from 'react';
import { Routes, Route } from "react-router-dom";

import Layout from '../Common/Layout';
import Nav from './Nav';
import List from './List';
import Groups from './Groups';
import Admins from './Admins';

const Permissions = () => {
    return <Layout>
        <>
            <Nav active="Permissions" />

            <Routes>
                <Route path="/" element={<List />} />
                <Route path="/groups" element={<Groups />} />
                <Route path="/admins" element={<Admins />} />
            </Routes>
        </>
    </Layout>;
};

export default Permissions;