import { useEffect, useState, useCallback, useReducer } from 'react';

import Loader from '../Common/Loader';
import FormGroup from './FormGroup';

import { permService } from '../../Services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencil,
    faCancel,
    faPlus,
    faUsers,
    faCircleNotch,
    faRotateLeft
  } from '@fortawesome/free-solid-svg-icons';

const groupsReducer = (state, event) => ({
    ...state,
    [event.key]: event.value
});

const Groups = () => {
    const [groups, setGroups] = useReducer(groupsReducer, {});
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(0);
    const [error, setError] = useState(null);
    const [editGroup, setEditGroup] = useState(null);

    const changeStatus = async (id, item) => {
        setLoadingStatus(id);

        item.status = item.status ? 0 : 1;

        const data = await permService.sendGroupStatus({
            id: id,
            status: item.status
        });

        if (data.res === 'OK') {
            setGroups({
                key: item.id,
                value: item
            });
        }

        setLoadingStatus(0);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const data = await permService.getGroups({
            org_id: [0]
        });
        if (data.list) {
            data.list.forEach((item, key) => {
                setGroups({
                    key: item.id,
                    value: item
                });
            });
        }
        if (data.error)
            setError(data.error);

        setLoading(false);
      }, []);

    useEffect(() => {
            fetchData();
      }, [fetchData]);

    
    return <>
        <h1 className="add-btn">
            <FontAwesomeIcon
                icon={faUsers}
                style={{ fontSize: 25, color: "#59748A" }}
            />
            <span>Groups</span>
            <button type="button" className="btn blue" onClick={() => setEditGroup({})}>
                <FontAwesomeIcon
                    icon={faPlus}
                    style={{ fontSize: 20, color: "#fff" }}
                /> Add a group
            </button>
        </h1>
        {loading ? 
            <Loader/> : 
            <>
                {error ? <div className="alert error">{error}</div> :
                    <>
                        <div className="groups-list">
                            {Object.keys(groups).length ?// eslint-disable-next-line
                            <>
                                <div className="header">
                                    <div className="id">#</div>
                                    <div className="name">Name</div>
                                    <div className="actions">Actions</div>
                                </div>
                                {Object.entries(groups).map(([key, item]) => {
                                    return <div className={`item ${item.status ? '' : 'inactive'}`} key={key}>
                                        <div className="id">{key}</div>
                                        <div className="name">
                                            <span className="poiner" onClick={() => {
                                            if (item.status)
                                                setEditGroup(item);
                                        }}>{item.name}</span>
                                        </div>
                                        <div className="actions">
                                            {item.status ? <FontAwesomeIcon
                                                icon={faPencil}
                                                style={{ fontSize: 20, color: "#3B76EF" }}
                                                onClick={() => {
                                                    setEditGroup(item);
                                                }}
                                                title="Edit"
                                            /> : ''}
                                            {loadingStatus === item.id ? <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                style={{ fontSize: 20, color: "#ccc" }}
                                                spin
                                            /> :
                                            <FontAwesomeIcon
                                                icon={item.status ? faCancel : faRotateLeft}
                                                style={{ fontSize: 20, color: "#3B76EF" }}
                                                onClick={() => {
                                                    changeStatus(item.id, item)
                                                }}
                                                title={item.status ? 'Block' : 'Unblock'}
                                            />}
                                        </div>
                                    </div>
                                })}
                            </> : <div className="empty-list">No groups</div>}
                        </div>
                    </>
                }
            </>
        }
        {editGroup ? <FormGroup group={editGroup} setGroup={setEditGroup} setGroups={setGroups} /> : ''}
    </>;
};

export default Groups;