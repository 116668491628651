import { apiUrl } from './';
import { authService } from './';

const getAll = async () =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const res = await authService.handleResponse(apiUrl + '/versions', requestOptions);
    return res.list;
}

const getVersion = async (id) =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    const res = await authService.handleResponse(apiUrl + '/versions/' + id, requestOptions);
    return res;
}
const sendVersion = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/versions/' + (data.id || ''), requestOptions);
    return res;
}

export const versionService = {
    getAll,
    getVersion,
    sendVersion
};