import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { authService } from '../../Services';
import { uploadsUrl } from '../../Services/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faLock,
    faCircleNotch
  } from '@fortawesome/free-solid-svg-icons';

const Login = ({ setUser }) => {
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setError(false);

    const user = await authService.login({
      login,
      password
    });
    if (user.hid) {
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
    }
  }

  return (
    <div className="login-wrapper">
      <div className="login">
        <div className="logo">
            <img src={`${uploadsUrl}/logos/aaus.png`} alt="AAUS admin" />
        </div>
        <form onSubmit={handleSubmit}>
            <div className="input-icon">
                <FontAwesomeIcon
                    icon={faUser}
                    style={{ fontSize: 20, color: "white" }}
                />
                <input type="text" name="login" placeholder="Login" onChange={e => setLogin(e.target.value)} />
            </div>
            <div className="input-icon">
                <FontAwesomeIcon
                    icon={faLock}
                    style={{ fontSize: 20, color: "white" }}
                />
                <input type="password" name="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
            </div>
            <button type="submit" className="white">{loading ? <span><FontAwesomeIcon
                    icon={faCircleNotch}
                    style={{ fontSize: 20, color: '#3B76EF'}}
                    spin
                /> </span> : ''}Sign in</button>
            {error ? <div className="error">The login or password is incorrect</div> : ''}
        </form>
    </div>
  </div>
  )
}

Login.propTypes = {
  setUser: PropTypes.func.isRequired
}

export default Login;