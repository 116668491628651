import { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';

import Loader from '../Common/Loader';

import { permService } from '../../Services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleNotch,
    faClose
  } from '@fortawesome/free-solid-svg-icons';

const FormGroup = ({group, setGroup, setGroups}) => {
    const [groupName, setGroupName] = useState(group.name || '');
    const [groupPermissions, setGroupPermissions] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [loading, setLoading] = useState(false);
    const [formUploading, setformUploading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: '100%',
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #b0e2ff',

        })
    }

    const closeModal = () => {
        setGroup(null);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSuccess(false);
        setFormError(false);
        setformUploading(true);

        let list = '';
        groupPermissions.forEach(item => {
            list += (list ? ',' : '') + item.value;
        });

        const data = await permService.sendGroup({
            id: group.id || 0,
            name: groupName,
            permissions: list
        });

        if (data.error)
            setFormError(data.error);
        else if (data.res > 0) {
            setGroup({
                id: data.res,
                name: groupName,
                permissions: list
            });

            setGroups({
                key: data.res,
                value: {
                    id: data.res,
                    name: groupName,
                    permissions: list,
                    status: 1
                }
            });

            setFormSuccess(true);
            setformUploading(false);
            setTimeout(() => {
                setFormSuccess(false);
            }, 5000);
        }
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const data = await permService.getAll();
        let list = [],
            existed = (group.permissions || '').split(','),
            existedList = [];

        data.list.forEach(item => {
            list.push({
                value: item.name,
                label: item.name
            });

            if (existed.includes(item.name)) {
                existedList.push({
                    value: item.name,
                    label: item.name
                }); 
            }
        });
        setPermissions(list);
        setGroupPermissions(existedList);

        setLoading(false);
      }, [group]);

    useEffect(() => {
            fetchData();
    }, [fetchData]);

    return <div className="modal">
        <div className="wrapper">
            <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: 25 }}
                className="close"
                onClick={closeModal}
            />
            <h2>{group.id ? 'Edit' : 'Add'} a group</h2>

            <form onSubmit={handleSubmit}>
                <input type="hidden" name="ID" defaultValue={group.id || ''} />
                <div className="form-part">
                    <div className="form-control">
                        <label>Name</label>
                        <input type="text" name="name" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                    </div>
                </div>
                {loading ? <Loader /> :
                    <div className="form-part">
                        <div className="form-control">
                            <label>Permissions</label>
                            <Select
                                defaultValue={groupPermissions}
                                onChange={setGroupPermissions}
                                styles={customStyles}
                                isMulti={true}
                                width='100%'
                                menuColor='red'
                                options={permissions}
                                placeholder="Select one or more permission types..."
                            />
                        </div>
                    </div>
                }
                <button type="submit" className="blue">{formUploading ? <span><FontAwesomeIcon
                    icon={faCircleNotch}
                    style={{ fontSize: 20, color: '#fff'}}
                    spin
                /> </span> : ''}Submit</button>
            </form>
            {formError ? <div className="alert error">{formError}</div> : ''}
            {formSuccess ? <div className="alert success">Done!</div> : ''}
        </div>
    </div>;
};

export default FormGroup;