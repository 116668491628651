import EditOrganization from './EditOrganization';

const AddOrganization = ({setEditOrg, updateItem, index, setAdd}) => {
    return <div className="modal">
        <div className="wrapper">
            <EditOrganization setEditOrg={setEditOrg} updateItem={updateItem} index={index} setAdd={setAdd} />
        </div>
    </div>;
};

export default AddOrganization;