import { useEffect, useState, useCallback, useReducer } from 'react';

import Loader from '../Common/Loader';
import AddAdmin from './AddAdmin';

import { permService } from '../../Services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCancel,
    faPlus,
    faUserSecret,
    faCircleNotch,
    faRotateLeft
  } from '@fortawesome/free-solid-svg-icons';

const adminsReducer = (state, event) => ({
    ...state,
    [event.key]: event.value
});

const Admins = () => {
    const [admins, setAdmins] = useReducer(adminsReducer, {});
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(0);
    const [error, setError] = useState(null);
    const [addAdmin, setAddAdmin] = useState(null);

    const changeStatus = async (id, item) => {
        setLoadingStatus(id);

        item.status = item.status ? 0 : 1;

        const data = await permService.sendAdminStatus({
            id: id,
            status: item.status
        });

        if (data.res === 'OK') {
            setAdmins({
                key: item.id,
                value: item
            });
        }

        setLoadingStatus(0);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const data = await permService.getAdmins({
            org_id: [0]
        });
        if (data.list) {
            data.list.forEach((item, key) => {
                setAdmins({
                    key: key,
                    value: item
                });
            });
        }
        if (data.error)
            setError(data.error);

        setLoading(false);
      }, []);

    useEffect(() => {
            fetchData();
      }, [fetchData]);
    
    return <>
        <h1 className="add-btn">
            <FontAwesomeIcon
                icon={faUserSecret}
                style={{ fontSize: 25, color: "#59748A" }}
            />
            <span>Global administrator</span>
            <button type="button" className="btn blue" onClick={() => setAddAdmin({})}>
                <FontAwesomeIcon
                    icon={faPlus}
                    style={{ fontSize: 20, color: "#fff" }}
                /> Add an admin
            </button>
        </h1>
        {loading ? 
            <Loader/> : 
            <>
                {error ? <div className="alert error">{error}</div> :
                    <>
                        <div className="admins-list">
                            {Object.keys(admins).length ?// eslint-disable-next-line
                            <>
                                <div className="header">
                                    <div className="id">#</div>
                                    <div className="name">Name</div>
                                    <div className="name">Login</div>
                                    <div className="actions">Actions</div>
                                </div>
                                {Object.values(admins).map((item,key) => {
                                    return <div className={`item ${item.status ? '' : 'inactive'}`} key={key}>
                                        <div className="id">{item.id}</div>
                                        <div className="name">{item.name}</div>
                                        <div className="name">{item.Uid}</div>
                                        <div className="actions">
                                            {item.id !== 108 && item.id !== 72569 ? (
                                                loadingStatus === item.id ? <FontAwesomeIcon
                                                    icon={faCircleNotch}
                                                    style={{ fontSize: 20, color: "#ccc" }}
                                                    spin
                                                /> : 
                                                <FontAwesomeIcon
                                                    icon={item.status ? faCancel : faRotateLeft}
                                                    style={{ fontSize: 20, color: "#3B76EF" }}
                                                    onClick={() => {
                                                        changeStatus(item.id, item)
                                                    }}
                                                    title={item.status ? 'Block' : 'Unblock'}
                                                />
                                            ) : ''}
                                        </div>
                                    </div>
                                })}
                            </> : <div className="empty-list">No admins</div>}
                        </div>
                    </>
                }
            </>
        }
        {addAdmin ? <AddAdmin addAdmin={addAdmin} setAddAdmin={setAddAdmin} setAdmins={setAdmins} /> : ''}
    </>;
};

export default Admins;