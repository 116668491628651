import { Link, useLocation } from 'react-router-dom';

import { authService } from '../../Services';
import { uploadsUrl } from '../../Services/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRightFromBracket,
    faGlobe,
    faUsers,
    faCode,
    faSearch
  } from '@fortawesome/free-solid-svg-icons';

const Layout = ({editUsers, children}) => {
    return <div className="global-wrapper">
        <div className="nav-panel">
            <div>
                <Link to="/" className="logo">
                    <img src={`${uploadsUrl}/logos/aaus.png`} alt="AAUS admin" />
                </Link>
                <Navigation />
                <div className="logout"> 
                    <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        style={{ fontSize: 30, color: "white" }}
                        onClick={() => {authService.logout()}}
                        title="Sign out"
                    />
                </div>
            </div>
        </div>
        <main>
            {children}
        </main>
    </div>;
};

export default Layout;

const Navigation = () => {
    const location = useLocation();

    return (
        <nav>
            <Link to="/" className={location.pathname === "/" ? 'active' : ''}>
                <FontAwesomeIcon
                    icon={faGlobe}
                    style={{ fontSize: 30, color: "white" }}
                    title="Organization members"
                />
            </Link>
            <Link to="/permissions" className={location.pathname.indexOf("/permissions") >= 0 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon={faUsers}
                    style={{ fontSize: 30, color: "white" }}
                    title="Groups and permissions"
                />
            </Link>
            <Link to="/users" className={location.pathname.indexOf("/users") >= 0 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon={faSearch}
                    style={{ fontSize: 30, color: "white" }}
                    title="User search"
                />
            </Link>
            <Link to="/versions" className={location.pathname.indexOf("/versions") >= 0 ? 'active' : ''}>
                <FontAwesomeIcon
                    icon={faCode}
                    style={{ fontSize: 30, color: "white" }}
                    title="Version control"
                />
            </Link>
        </nav>
    )
};