import { useEffect, useState, useCallback, useReducer } from 'react';
import Loader from '../Common/Loader';
import { orgService } from '../../Services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClose,
    faCircleNotch
  } from '@fortawesome/free-solid-svg-icons';

const profileReducer = (state, event) => ({
    ...state,
    [event.key]: event.value
});

const EditUser = ({id, editUser, setEditUser, setAddUser, updateUsers}) => {
    const [profile, setProfile] = useReducer(profileReducer, {});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [formUploading, setformUploading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formSuccess, setFormSuccess] = useState(false);

    const closeModal = () => {
        setEditUser(null);
        setAddUser(false);
        document.body.classList.remove('overflow');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSuccess(false);
        setFormError(false);
        setformUploading(true);
        
        const data = await orgService.sendUser({
            ...profile,
            'userID': editUser,
            'orgID': id
        });

        if (data.error)
            setFormError(data.error);
        else if (data.res > 0) {
            setFormSuccess(true);
            setTimeout(() => {
                setFormSuccess(false);
            }, 5000);

            updateUsers();

            closeModal();
        }

        setformUploading(false);
    }

    const changeValue = (key, value) => {
        setProfile({
            key: key,
            value: value,
        }); 
    }

    const fetchData = useCallback(async () => {
        if (editUser) {
            setLoading(true);

            const data = await orgService.getUser({
                'userID': editUser,
                'orgID': id
            });
            if (data.user) {
                Object.keys(data.user).forEach((key) => {
                    setProfile({
                        key: key,
                        value: data.user[key]
                    });
                });
            }
            if (data.error)
                setError(data.error);

            setLoading(false);
        }
      }, [editUser, id])

    useEffect(() => {
            fetchData();
      }, [fetchData]);

    return <div className="modal">
        <div className="wrapper">
            <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: 25 }}
                className="close"
                onClick={closeModal}
            />
            <h2>DSO User Profile ({profile.LastName}, {profile.FirstName})</h2>
            {loading ? <Loader /> : <>
                {error ? <div className="alert error">{error}</div> : ''}
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="id" defaultValue={profile.id} />
                    <div className="form-part">
                        <h3>Contact Info</h3>
                        <div className="form-control">
                            <label>Phone</label>
                            <input type="text" name="Phone" value={profile.Phone || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                        <div className="form-control">
                            <label>Email</label>
                            <input type="email" name="Email" value={profile.Email || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                    </div>
                    <button type="submit" className="blue">{formUploading ? <span><FontAwesomeIcon
                        icon={faCircleNotch}
                        style={{ fontSize: 20, color: '#fff'}}
                        spin
                    /> </span> : ''}Submit</button>
                </form>
                {formError ? <div className="alert error">{formError}</div> : ''}
                {formSuccess ? <div className="alert success">Done!</div> : ''}
            </>}
        </div>
    </div>
};

export default EditUser;