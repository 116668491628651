import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleNotch
  } from '@fortawesome/free-solid-svg-icons';

const Loader = () => {
    return <div className="loader">
        <FontAwesomeIcon
            icon={faCircleNotch}
            style={{ fontSize: 40, color: '#4176c6'}}
            spin
        />
    </div>
};

export default Loader;