import { apiUrl } from './';
import { authService } from './';

const getAll = async () =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const res = await authService.handleResponse(apiUrl + '/permissions', requestOptions);
    return res;
}

const getGroups = async (data) =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const res = await authService.handleResponse(apiUrl + '/permissions/groups?org_id=' + data.org_id.join(','), requestOptions);
    return res;
}

const getAdmins = async (data) =>  {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const res = await authService.handleResponse(apiUrl + '/permissions/admins', requestOptions);
    return res;
}

const sendPermission = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/permissions/', requestOptions);
    return res;
}

const sendGroup = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/permissions/groups', requestOptions);
    return res;
}

const sendGroupStatus = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/permissions/groups/status', requestOptions);
    return res;
}

const sendAdminStatus = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/permissions/admins', requestOptions);
    return res;
}

const addAdmin = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/permissions/admins', requestOptions);
    return res;
}

export const permService = {
    getAll,
    getGroups,
    getAdmins,
    sendPermission,
    sendGroup,
    sendGroupStatus,
    sendAdminStatus,
    addAdmin
};