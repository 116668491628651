import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import { versionService } from '../../Services';
import Loader from '../Common/Loader';

import 'react-quill/dist/quill.snow.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCode,
    faCircleNotch
  } from '@fortawesome/free-solid-svg-icons';

const Form = ({user}) => {
    const params = useParams();
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [version, setVersion] = useState('');
    const [description, setDescription] = useState('');
    const [formUploading, setformUploading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formSuccess, setFormSuccess] = useState(false);
    const navigate = useNavigate();

    const modules = {
        toolbar: [
          [{'header': [1, 2, 3, 4, 5, false]}],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["link", "video"],
        ],
      };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSuccess(false);
        setFormError(false);
        setformUploading(true);

        if (!version || !description)
            setFormError('Please, enter reqired data');
        
        if (!formError) {
            const data = await versionService.sendVersion({
                id: params.id || 0,
                version,
                description
            });

            if (data.error)
                setFormError(data.error);
            else if (data.res > 0) {
                setFormSuccess(true);
                setTimeout(() => {
                    navigate("/versions");
                }, 3000);
            }
        }

        setformUploading(false);
    }

    const fetchData = useCallback(async () => {
        if (params.id) {
            setLoading(true);
            const data = await versionService.getVersion(params.id);
            if (data.version) {
                setVersion(data.version.version);
                setDescription(data.version.description);
            }
            if (data.error)
                setError(data.error);
            setLoading(false);
        }
      }, [params]);

    useEffect(() => {
            fetchData();
      }, [fetchData]);
    
    return <>
            <div className="top-panel">
                <h1>
                    <FontAwesomeIcon
                        icon={faCode}
                        style={{ fontSize: 25, color: "#59748A" }}
                    />
                    {params.id ? 'Edit' : 'New'} Version
                </h1>
            </div>
            {loading ? 
                <Loader/> : 
                <>
                    {error ? <div className="alert error">{error}</div> : ''}
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="ID" defaultValue={params.id} />
                        <div className="form-control">
                            <label>Version</label>
                            <input type="text" name="version" value={version || ''} onChange={(e) => setVersion(e.target.value)} />
                        </div>
                        <div className="form-control">
                            <label>Description</label>
                            <ReactQuill theme="snow" value={description} onChange={setDescription} modules={modules} />
                        </div>
                        <div>
                            <button type="submit" className="blue">{formUploading ? <span><FontAwesomeIcon
                                icon={faCircleNotch}
                                style={{ fontSize: 20, color: '#fff'}}
                                spin
                            /> </span> : ''}Submit</button>
                            {formError ? <div className="alert error">{formError}</div> : ''}
                            {formSuccess ? <div className="alert success">Done!</div> : ''}
                        </div>
                    </form>
                </>
            } 
        </>
};

export default Form;