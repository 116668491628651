//import { useEffect, useState, useCallback } from 'react';
import { Routes, Route } from "react-router-dom";

import Layout from '../Common/Layout';
import List from './List';
import Form from './Form';


const Versions = () => {
    return <Layout>
        <>
            <Routes>
                <Route path="/" element={<List />} />
                <Route path="/add" element={<Form />} />
                <Route path="/edit/:id" element={<Form />} />
            </Routes>
        </>
    </Layout>;
};

export default Versions;