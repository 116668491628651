import { useEffect, useState, useCallback, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { versionService } from '../../Services';
import Loader from '../Common/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCode,
    faPencil,
    faPlus
  } from '@fortawesome/free-solid-svg-icons';

const listReducer = (state, event) => ({
    ...state,
    [event.key]: {
        ...state[event.key],
        ...event.value
    }
})

const Versions = ({user}) => {
    const [list, setList] = useReducer(listReducer, {});
    const [loading, setLoading] = useState(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const data = await versionService.getAll();
        data.forEach((item, key) => {
            setList({
                key: key,
                value: item
            });
        });
        setLoading(false);
      }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return <>
            <div className="top-panel">
                <h1>
                    <FontAwesomeIcon
                        icon={faCode}
                        style={{ fontSize: 25, color: "#59748A" }}
                    />
                    Site versions
                    <a href="/versions/add">
                        <FontAwesomeIcon
                            icon={faPlus}
                            className="plus-icon"
                            style={{ fontSize: 20, color: "#A66DD4" }}
                        />
                    </a>
                </h1>
            </div>
            {loading ? 
                <Loader/> : 
            <div className="version-list">
                    <div className="header">
                        <div className="version">Version</div>
                        <div className="date">Last updated</div>
                        <div className="actions">Edit</div>
                    </div>
                    {Object.keys(list).length ? Object.entries(list).map(([key, item]) => {// eslint-disable-next-line
                        return (
                            <div key={item.id}>
                                <div className="version"><strong><Link to={`/versions/edit/${item.id}`}>{item.version}</Link></strong></div>
                                <div className="date">{item.fdate}</div>
                                <div className="actions">
                                    <span>Actions: </span>
                                    <Link to={`/versions/edit/${item.id}`}>
                                        <FontAwesomeIcon
                                            icon={faPencil}
                                            style={{ fontSize: 20, color: "#3B76EF" }}
                                            title="Edit"
                                        />
                                    </Link>
                                </div>
                            </div>
                            )
                        }) : <div className="empty-list">No versions</div>}
                </div>
            }
        </>;
    };

export default Versions;