import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { userService, permService } from '../../Services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClose,
    faCircleNotch
  } from '@fortawesome/free-solid-svg-icons';

const AddAdmin = ({addAdmin, setAddAdmin, setAdmins}) => {
    const [admin, setAdmin] = useState([]);
    // eslint-disable-next-line
    const [inputValue, setInputValue] = useState('');
    const [formUploading, setformUploading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formSuccess, setFormSuccess] = useState(false);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: '100%',
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #b0e2ff',

        })
    }

    const closeModal = () => {
        setAddAdmin(null);
        document.body.classList.remove('overflow');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSuccess(false);
        setFormError(false);
        setformUploading(true);

        if (!admin) {
            setFormError('Please, fill in required fields');
            setformUploading(false);
            return false;
        }
        
        const data = await permService.addAdmin({
            'id': admin.id
        });

        if (data.error)
            setFormError(data.error);
        else if (data.res === 'OK') {
            setFormSuccess(true);
            setTimeout(() => {
                setFormSuccess(false);
            }, 5000);

            setAdmins({
                key: admin.id,
                value: {
                    ...admin,
                    status: 1
                }
            });

            closeModal();
        }

        setformUploading(false);
    }

    const handleInputChange = value => {
        setInputValue(value);
    };
    
    const loadOptions = async (inputValue) => {
        const data = await userService.search({
            'name': inputValue
        });

        return data.list;
    };

    return <div className="modal">
        <div className="wrapper">
            <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: 25 }}
                className="close"
                onClick={closeModal}
            />
            <h2>Add a global admin</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-part">
                    <div className="form-control">
                        <label>User</label>
                        <AsyncSelect
                            onChange={setAdmin}
                            styles={customStyles}
                            width='100%'
                            menuColor='red'
                            getOptionLabel={e => e.name+' ('+e.Uid+')'}
                            getOptionValue={e => e.id}
                            loadOptions={loadOptions}
                            onInputChange={handleInputChange}
                            placeholder="Start typing a last name..."
                        />
                    </div>
                </div>
                <button type="submit" className="blue">{formUploading ? <span><FontAwesomeIcon
                    icon={faCircleNotch}
                    style={{ fontSize: 20, color: '#fff'}}
                    spin
                /> </span> : ''}Submit</button>
            </form>
            {formError ? <div className="alert error">{formError}</div> : ''}
            {formSuccess ? <div className="alert success">Done!</div> : ''}
        </div>
    </div>
};

export default AddAdmin;