import { useEffect, useState, useCallback, useReducer } from 'react';

import Loader from '../Common/Loader';

import { permService } from '../../Services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleNotch,
    faNetworkWired
  } from '@fortawesome/free-solid-svg-icons';

const permissionsReducer = (state, event) => ({
    ...state,
    [event.key]: event.value
});

const List = () => {
    const [permissions, setPermissions] = useReducer(permissionsReducer, {});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [permission, setPermission] = useState('');
    const [formUploading, setformUploading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormSuccess(false);
        setFormError(false);
        setformUploading(true);

        const data = await permService.sendPermission({
            permission: permission
        });

        if (data.error)
            setFormError(data.error);
        else if (data.res > 0) {
            setPermissions({
                key: data.res,
                value: permission
            });

            setFormSuccess(true);
            setformUploading(false);
            setPermission('');
            setTimeout(() => {
                setFormSuccess(false);
            }, 5000);
        }
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const data = await permService.getAll();
        if (data.list) {
            data.list.forEach((el, key) => {
                setPermissions({
                    key: el.id,
                    value: el.name
                });
            });
        }
        if (data.error)
            setError(data.error);

        setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <>
        <h1>
            <FontAwesomeIcon
                icon={faNetworkWired}
                style={{ fontSize: 25, color: "#59748A" }}
            />
            Permissions
        </h1>
        {loading ? 
            <Loader/> : 
            <>
                {error ? <div className="alert error">{error}</div> :
                    <div className="permission-list">
                        {Object.keys(permissions).length ?// eslint-disable-next-line
                        <>
                            <div className="header">
                                <div className="id">#</div>
                                <div className="name">Name</div>
                            </div>
                            {Object.entries(permissions).map(([key, item]) => {
                                return <div className="item" key={key}>
                                    <div className="id">{key}</div>
                                    <div className="name">{item}</div>
                                </div>
                            })}
                        </> : <div className="empty-list">No permissions</div>}
                    </div>
                }

                <h2>Add a permission type</h2>
                <form className="add-permission" onSubmit={handleSubmit}>
                    <div className="form-control">
                        <input type="text" name="permission" value={permission || ''} onChange={(e) => setPermission(e.target.value)} />
                    </div>
                    <button type="submit" className="blue">{formUploading ? <span><FontAwesomeIcon
                        icon={faCircleNotch}
                        style={{ fontSize: 20, color: '#fff'}}
                        spin
                    /> </span> : ''}Add</button>
                </form>
                {formError ? <div className="alert error">{formError}</div> : ''}
                {formSuccess ? <div className="alert success">Done!</div> : ''}
            </>
        }
    </>;
};

export default List;